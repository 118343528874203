import { useCurrentUser } from '@capturi/core'
import { usePageTitle } from '@capturi/react-utils'
import { PageHeading } from '@capturi/ui-components'
import { Flex } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

import Profile from './Profile'

const UserSettings: React.FC = () => {
  usePageTitle(t`Profile`)
  const currentUser = useCurrentUser()

  return (
    <>
      <PageHeading mb={8}>
        <Trans>Profile</Trans>
      </PageHeading>
      <Flex width="100%" mx="auto" justify="center">
        <Profile {...currentUser} />
      </Flex>
    </>
  )
}

export default UserSettings

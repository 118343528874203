import { OnChangeValue, Select } from '@capturi/ui-select'
import React, { useMemo, useState } from 'react'
import useSWR from 'swr'

type Props = {
  id: string
  value: string
  onChange: (timeZone: string) => void
}

type Option = {
  label: string
  value: string
}

const TimeZoneSelector: React.FC<Props> = ({ id, value, onChange }) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false)
  const { data } = useSWR<string[]>(
    shouldFetch ? 'authentication/me/time-zones' : null,
    {
      suspense: false,
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 60,
    },
  )

  const selectValue: Option = { label: value, value: value }
  const options: Option[] = useMemo(
    () => (data ?? []).map((d) => ({ label: d, value: d })),
    [data],
  )

  const handleFocus = (): void => {
    setShouldFetch(true)
  }
  const handleChange = (newValue: OnChangeValue<Option, false>): void => {
    if (newValue) onChange(newValue.value)
  }

  return (
    <Select
      id={id}
      isLoading={shouldFetch && !data}
      onFocus={handleFocus}
      value={selectValue}
      onChange={handleChange}
      options={options}
    />
  )
}

export default TimeZoneSelector

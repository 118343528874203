import { CURRENT_USER_URL, CurrentUser, mutateCurrentUser } from '@capturi/core'
import request, { getErrorMessage } from '@capturi/request'
import { Button, useToast } from '@capturi/ui-components'
import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import { Flex, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useLanguage } from 'i18n'
import usePreferredLanguage from 'i18n/usePreferredLanguage'
import React, { ChangeEvent, useMemo, useState } from 'react'

import TimeZoneSelector from './TimeZoneSelector'

type Props = CurrentUser

const Profile: React.FC<Props> = (props) => {
  const toast = useToast()
  const langUtils = useLanguage()

  const [name, setName] = useState(props.name || '')
  const [title, setTitle] = useState(props.title || '')
  const [language, setLanguage] = usePreferredLanguage(props.language)
  const [timeZone, setTimeZone] = useState<string>(props.timeZone)
  const [inProgress, setInProgress] = useState(false)

  const languageOptions: SelectOption[] = useMemo(
    () =>
      langUtils.languageOptions.map((lang) => ({
        label: lang.label,
        value: lang.code,
      })),
    [langUtils.languageOptions],
  )

  const selectedLanguage: SelectOption | undefined = useMemo(() => {
    const lang = langUtils.findLanguage(language)
    return lang ? { label: lang.label, value: lang.code } : undefined
  }, [langUtils, language])

  const handleSave = async (): Promise<void> => {
    try {
      setInProgress(true)
      await request.put(CURRENT_USER_URL, {
        json: { name, title, language, timeZone },
      })

      mutateCurrentUser(
        (data?: CurrentUser) =>
          data && {
            ...data,
            name,
            title,
            language,
            timeZone,
          },
      )
      toast({ title: t`Profile updated`, status: 'success' })
      setInProgress(false)
      // Update language context and load message catalog - this rerenders everything
      langUtils.setLanguage(language)
    } catch (error) {
      const errorMsg = getErrorMessage(error)

      toast({
        title: t`This is embarrassing`,
        description: errorMsg,
        status: 'error',
      })
      setInProgress(false)
    }
  }

  const handleLanguageChange = (
    option: OnChangeValue<SelectOption, boolean>,
  ): void => {
    const value = (option as SelectOption).value
    setLanguage(value)
  }

  return (
    <Flex direction="column" justifyContent="space-between" w="600px" h="100%">
      <Stack spacing={4}>
        <FormControl>
          <FormLabel htmlFor="Name">
            <Trans>Name</Trans>
          </FormLabel>
          <Input
            id="Name"
            placeholder={t`Full name`}
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              setName(e.target.value)
            }
            isDisabled={inProgress}
            size="sm"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="Title">
            <Trans>Title</Trans>
          </FormLabel>
          <Input
            id="Title"
            placeholder={t`Title`}
            value={title}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              setTitle(e.target.value)
            }
            isDisabled={inProgress}
            size="sm"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="Email">
            <Trans>E-mail</Trans>
          </FormLabel>
          <Input
            type="text"
            id="Email"
            isDisabled
            value={props.email}
            size="sm"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="Language">
            <Trans>Language</Trans>
          </FormLabel>
          <Select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            options={languageOptions}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="timeZone">
            <Trans>Time zone</Trans>
          </FormLabel>
          <TimeZoneSelector
            onChange={setTimeZone}
            value={timeZone}
            id="timeZone"
          />
        </FormControl>
      </Stack>
      <Button
        primary
        onClick={handleSave}
        isLoading={inProgress}
        mt={4}
        isDisabled={!name}
      >
        <Trans>Save changes</Trans>
      </Button>
    </Flex>
  )
}

export default Profile
